<script setup>
const speed = ref(300);
const animationStep = ref("implement");

onMounted(() => {
  setTimeout(() => {
    animationStep.value = "implemen";
    setTimeout(() => {
      animationStep.value = "impleme";
      setTimeout(() => {
        animationStep.value = "implem";
        setTimeout(() => {
          animationStep.value = "imple";
          setTimeout(() => {
            animationStep.value = "impl";
            setTimeout(() => {
              animationStep.value = "imp";
              setTimeout(() => {
                animationStep.value = "im";
              }, speed.value);
            }, speed.value);
          }, speed.value);
        }, speed.value);
      }, speed.value);
    }, speed.value);
  }, speed.value);
});
</script>

<template>
  <div class="full-logo-wrapper">
    <svg
      class="full-logo"
      width="100%"
      height="100%"
      viewBox="0 0 378.36 42.54"
      fill="none"
      :class="animationStep"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m0,42.54V0h7.86v42.54H0Z" fill="currentColor" />
      <path
        d="m53.04,42.54V12.18l-11.04,29.76h-5.7l-10.98-30.12v30.72h-7.5V0h10.44l11.16,30.3L50.64,0h9.9v42.54h-7.5Z"
        fill="currentColor"
      />
      <path
        v-if="animationStep.length >= 3"
        d="m87.18,27.3h-9v15.24h-7.68V0h16.68c9.78,0,15,5.46,15,13.62s-5.22,13.68-15,13.68Zm-.3-20.52h-8.7v13.74h8.7c5.16,0,7.5-2.76,7.5-6.84s-2.34-6.9-7.5-6.9Z"
        fill="currentColor"
      />
      <path
        v-if="animationStep.length >= 4"
        d="m107.94,42.54V0h7.74v35.22h19.74v7.32h-27.48Z"
        fill="currentColor"
      />
      <path
        v-if="animationStep.length >= 5"
        d="m140.94,42.54V0h30.3v7.02h-22.56v9.9h19.86v6.96h-19.86v11.64h22.56v7.02h-30.3Z"
        fill="currentColor"
      />
      <path
        v-if="animationStep.length >= 6"
        d="m213.83,42.54V12.18l-11.04,29.76h-5.7l-10.98-30.12v30.72h-7.5V0h10.44l11.16,30.3L211.44,0h9.9v42.54h-7.5Z"
        fill="currentColor"
      />
      <path
        v-if="animationStep.length >= 7"
        d="m231.3,42.54V0h30.3v7.02h-22.56v9.9h19.86v6.96h-19.86v11.64h22.56v7.02h-30.3Z"
        fill="currentColor"
      />
      <path
        v-if="animationStep.length >= 8"
        d="m295.01,42.54l-18.42-29.22v29.22h-7.62V0h8.22l18.18,28.8V0h7.62v42.54h-7.98Z"
        fill="currentColor"
      />

      <path
        v-if="animationStep.length >= 9"
        d="m328.67,7.2v35.34h-7.74V7.2h-12.6V0h32.94v7.2h-12.6Z"
        fill="currentColor"
      />
    </svg>
    <svg
      class="full-logo-tab"
      width="100%"
      height="100%"
      viewBox="0 0 378.36 42.54"
      fill="none"
      :class="animationStep"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m378.36,42.54v-5h-32.35v5h32.35Z" fill="currentColor" />
    </svg>
  </div>
</template>
<style scoped>
.full-logo {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: auto;

}
.full-logo-tab {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: auto;
  
  &.implemen {
    transform: translateX(-9%);
  }
  &.impleme {
    transform: translateX(-20%);
  }
  &.implem {
    transform: translateX(-30%);
  }
  &.imple {
    transform: translateX(-44%);
  }
  &.impl {
    transform: translateX(-53%);
  }
  &.imp {
    transform: translateX(-62%);
  }
  &.im {
    transform: translateX(-73.6%);
  }
}
</style>
